









































import {
  Component,
  Vue
} from 'vue-property-decorator';
 

import {
  Auth
} from '@/store/auth'
import {
  Core
} from '@/store/core'
import {
  User
} from '@/store/user'

@Component({
  components: {
     
  },
})
export default class Home extends Vue {
  private dashboard: any = null
  private user: any = {}
  private years: any = []
  private response: boolean = false
  $vs:any
  public async created() {
    this.user = await User.getUser(); 
    this.years = await Core.getHttp(`/api/eit/v1/year`) 
    await this.autoGoToCheck()
    this.response = true
  }

    private async autoGoToCheck(){
        console.log(this.years);
        let currentYears = this.years[(this.years.length)-1]
        if(this.$route.query.auto){ 
            await this.openEit(currentYears.id,currentYears.status)
        }
    }

  public async openEit(yearId:any,status:boolean){

    if(!status){
      if(this.user.ext_link.in_up){
        let userInAnswer = await Core.getHttp(`/api/iit/v2/ansewer/user/?user=${this.user.pk}&year=${yearId}`)
        if(userInAnswer.length > 0){
          await this.$router.push(`/eit/detail?year=${yearId}`)
        }else{
          alert('กรุณาประเมิน IIT ก่อน')
        }
      }else{
        await this.$router.push(`/eit/detail?year=${yearId}`)
      }
    } else {
      await this.openNotification('top-right', '#D65B6D', `<i class="em em-lock" aria-role="presentation" aria-label="LOCK"></i>`,'ปิดการให้ประเมิน','ไม่สามารถประเมินได้ เนื่องจากระบบปิดการให้ประเมินแล้ว กรุณาติดต่อผู้ดูแลระบบ')
    }



    console.log(this.user.ext_link.in_up);
     
  }

  async openNotification(position:any = null, color:any, icon:any,title:string,text:string) {

    const noti = this.$vs.notification({
      icon,
      color,
      position,
      title: title,
      text: text
    })
  }

}
